<template>
  <v-container fluid class="ml-0">
    <v-row
      ><v-col>
        <v-btn color="primary" class="ma-1" @click="LoadColors">
          <v-icon left> mdi-download</v-icon>
          Load
        </v-btn>
        <v-btn color="primary" class="my-1" @click="UpdateColors">
          <v-icon left> mdi-content-duplicate </v-icon>
          Save
        </v-btn>
      </v-col></v-row
    >
    <v-row
      ><v-col cols="12">
        <v-jsoneditor v-model="jsonInput" height="80vh" :options="jsonoptions" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VJsoneditor from "v-jsoneditor/src/index";
import ColorTheme from "@/support/colortheme.js";

export default {
  components: {
    VJsoneditor,
  },

  data: () => ({
    jsonInput: {
      help: "please load a suitable tool entry to edit",
    },
    jsonoptions: { mode: "code" },
  }),

  mounted() {
    this.LoadColors();
  },

  computed: {
    authObject() {
      return {
        editEnable: this.$store.getters["menu/canEditTools"],
        permissions: this.$store.getters["user/permissions"],
      };
    },
  },
  methods: {
    LoadColors() {
      this.jsonInput = {
        light: ColorTheme.light,
        dark: ColorTheme.dark,
        styling: ColorTheme.styling,
      };
    },
    UpdateColors() {
      try {
        var data = this.jsonInput;
        ColorTheme.light = JSON.parse(JSON.stringify(data.light));
        ColorTheme.dark = JSON.parse(JSON.stringify(data.dark));
        ColorTheme.styling = JSON.parse(JSON.stringify(data.styling));
      } catch (error) {
        console.log("Invalid JSON data");
        return false;
      }
      this.RefreshData();
      return true;
    },
    RefreshData() {
      console.log("set styling");
      this.$store.commit("setStyling", ColorTheme.styling);
      console.log("update theme");
      ColorTheme.update(this.$vuetify);
    },
  },
};
</script>

<style>
div.jsoneditor-menu {
  background-color: #455a64;
  border: thin solid #263238;
}
div.jsoneditor {
  border: thin solid #263238;
}
a.jsoneditor-poweredBy {
  color: #eceff1;
}
</style>
